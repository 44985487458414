import React from "react";

let SERVER_URL;
if (process.env.SERVER_URL !== undefined) {
  SERVER_URL = process.env.SERVER_URL;
} else {
  throw new Error("SERVER_URL environment variable is not defined.");
}

const Invite = () => {
  const statuses = [
    "success",
    "already_joined",
    "expired",
    "token_used",
    "invalid",
  ];
  const url = window.location;
  const searchParams = new URLSearchParams(url.search);

  const status = searchParams.get("status");
  const projectName = decodeURI(searchParams.get("pname") || "project_name");

  if (statuses.includes(status))
    return (
      <html>
        <head>
          <meta charset="utf-8" />
          <meta name="viewport" content="initial-scale=1, width=device-width" />
          <link rel="stylesheet" href={`${SERVER_URL}/css/invite.css`} />
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap"
          />
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap"
          />
          <title>Invite</title>
          <link
            rel="icon"
            href={`${SERVER_URL}/images/favicon.png`}
            type="image/x-icon"
          />
        </head>
        <body>
          <div class="splash">
            <div
              class={
                status === "success"
                  ? "splash-inner-success"
                  : status === "already_joined"
                  ? "splash-inner-already-joined"
                  : status === "expired"
                  ? "splash-inner-expired"
                  : status === "token_used"
                  ? "splash-inner-token-used"
                  : status === "invalid"
                  ? "splash-inner-invalid"
                  : ""
              }
            >
              <div class="frame-parent">
                <div class="logo-parent">
                  <img
                    class="logo-icon"
                    alt=""
                    src={`${SERVER_URL}/images/invite/logo.svg`}
                  />
                  <b class="app-name">TIA</b>
                </div>
                {status === "success" ? (
                  <img
                    class="frame-inner"
                    alt=""
                    src={`${SERVER_URL}/images/invite/confetti.svg`}
                  />
                ) : (
                  ""
                )}
                {status === "expired" ? (
                  <img
                    class="frame-inner"
                    alt=""
                    src={`${SERVER_URL}/images/invite/expired.svg`}
                  />
                ) : (
                  ""
                )}
                <div class="status-message-parent">
                  {status === "success" ? (
                    <div class="status-message-container">
                      <p class="status-message">You have successfully joined</p>
                      <p class="status-message">
                        <b>{projectName}</b>
                      </p>
                    </div>
                  ) : status === "already_joined" ? (
                    <div class="status-message-container">
                      <p class="status-message">You have already joined</p>
                      <p class="status-message">
                        <b>{projectName}</b>
                      </p>
                    </div>
                  ) : status === "expired" ? (
                    <div class="status-message-container">
                      <p class="status-message">
                        Token is expired. Please contact the Scrum Master of
                      </p>
                      <p class="status-message">
                        <b>{projectName}</b> for further assistance.
                      </p>
                    </div>
                  ) : status === "token_used" ? (
                    <div class="status-message-container">
                      <p class="status-message">Token is already been used.</p>
                      <p class="status-message">
                        Please contact the Scrum Master of
                      </p>
                      <p class="status-message">
                        <b>{projectName}</b> for further assistance.
                      </p>
                    </div>
                  ) : status === "invalid" ? (
                    <div class="status-message-container">
                      <p class="status-message">Your request is invalid</p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div class="button">
                  {status === "invalid" ? (
                    <a href={SERVER_URL} class="button-label">
                      GO BACK HOME
                    </a>
                  ) : (
                    <a
                      href="https://amstel.stratpoint.dev/"
                      class="button-label"
                    >
                      LOGIN
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </body>
      </html>
    );
  else window.location.href = SERVER_URL;
  return <></>;
};

export default Invite;
