import React from "react";

let SERVER_URL;
if (process.env.SERVER_URL !== undefined) {
  SERVER_URL = process.env.SERVER_URL;
} else {
  throw new Error("SERVER_URL environment variable is not defined.");
}

const sitemap = [
  { name: "Home", url: `${SERVER_URL}/` },
  { name: "How It works", url: `${SERVER_URL}/how-it-works` },
  { name: "Frequently Ask Questions", url: `${SERVER_URL}/faqs` },
  { name: "Privacy Notice", url: `${SERVER_URL}/privacy-notice` },
  { name: "Contact Us", url: `${SERVER_URL}/contact` },
];

const Footer = () => {
  return (
    <>
      <div class="row">
        <div class="col-lg-12">
          <div class="footer__cta">
            <div class="shape-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="357"
                height="315.029"
                viewBox="0 0 357 315.029"
              >
                <path
                  data-name="Path 1449"
                  d="M76.1-157.222C91.746-135.8 87.2-94.273 99.993-61.945c12.7 32.328 42.661 55.459 39.248 73.282-3.318 17.823-40.007 30.337-65.6 43.325-25.5 12.988-39.912 26.545-60.01 42.566-20.1 16.116-46.074 34.6-63.328 27.682-17.349-6.921-25.976-39.153-59.915-59.82s-93.1-29.768-105.325-51.478 22.373-56.028 43.609-93.949c21.331-37.921 29.2-79.35 53.563-96.793 24.459-17.444 65.414-10.9 103.9-6.921 38.396 3.982 74.326 5.404 89.965 26.829z"
                  transform="translate(217.489 188.626)"
                />
              </svg>
            </div>
            <div class="shape-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="357"
                height="315.029"
                viewBox="0 0 357 315.029"
              >
                <path
                  data-name="Path 1449"
                  d="M76.1-157.222C91.746-135.8 87.2-94.273 99.993-61.945c12.7 32.328 42.661 55.459 39.248 73.282-3.318 17.823-40.007 30.337-65.6 43.325-25.5 12.988-39.912 26.545-60.01 42.566-20.1 16.116-46.074 34.6-63.328 27.682-17.349-6.921-25.976-39.153-59.915-59.82s-93.1-29.768-105.325-51.478 22.373-56.028 43.609-93.949c21.331-37.921 29.2-79.35 53.563-96.793 24.459-17.444 65.414-10.9 103.9-6.921 38.396 3.982 74.326 5.404 89.965 26.829z"
                  transform="translate(217.489 188.626)"
                />
              </svg>
            </div>
            <div class="text-light footer__cta_content">
              <span>Reach out to us</span>
              <h3 class="mb-0 footer-cta">
                Interested in using TIA for your project?
              </h3>
            </div>
            <div class="footer__cta_action">
              <a class="btn btn-light btn-zoom" href={`${SERVER_URL}/contact`}>
                Get in touch
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row footer__widget">
        <div class="col-lg-4">
          <div class="footer__widget_logo mb-5">
            <img
              src={`${SERVER_URL}/images/contact/widget-logo-1.png`}
              alt="widget-logo"
            />
          </div>
        </div>
        <div class="col-lg-4">
          <div class="text-light footer__widget_sitemap mb-5">
            <h3 class="footer-sitemap base-font">Sitemap</h3>
            <ul class="unstyle-list small">
              {sitemap.map((menu) => (
                <li class="mb-2" key={menu.name}>
                  <a class="text-light" href={menu.url}>
                    {menu.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div class="row footer__footer">
        <div class="col-lg-6">
          <div class="footer__footer_copy text-light">
            <p>
              © Copyright {`1998-${new Date().getFullYear()}`}{" "}
              <a
                class="text-light"
                href="https://stratpoint.com/"
                target="_blank"
              >
                Stratpoint.
              </a>{" "}
              All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
