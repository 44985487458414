import React, { useState, useRef, useEffect } from "react";

let SERVER_URL;
if (process.env.SERVER_URL !== undefined) {
  SERVER_URL = process.env.SERVER_URL;
} else {
  throw new Error("SERVER_URL environment variable is not defined.");
}

const menu = [
  { name: "Home", url: `${SERVER_URL}/` },
  { name: "How It Works", url: `${SERVER_URL}/how-it-works` },
  { name: "FAQs", url: `${SERVER_URL}/faqs` },
  { name: "Contact Us", url: `${SERVER_URL}/contact` },
];

const noNav = [{ name: "Privacy Notice", url: `${SERVER_URL}/privacy-notice` }];

const NavBar = () => {
  const [activeLink, setActiveLink] = useState(null);
  const linkRefs = useRef([]);

  useEffect(() => {
    const currentUrl = window.location.href;
    const linkIndex =
      menu.length -
      1 -
      menu
        .slice()
        .reverse()
        .findIndex((link) => currentUrl.includes(link.url));
    const noNavIndex = noNav.findIndex((link) => currentUrl.includes(link.url));

    setActiveLink(noNavIndex !== -1 ? -1 : linkIndex !== -1 ? linkIndex : 0);
  }, []);

  const handleLinkClick = (index) => {
    setActiveLink(index);
    window.location.href = menu[index].url;
  };

  return (
    <>
      <a href={SERVER_URL} class="navbar-brand pb-3">
        <img
          src={`${SERVER_URL}/images/site-navigation/logo.png`}
          alt="site-logo"
        />
      </a>
      <button
        type="button"
        class="navbar-toggler collapsed"
        data-toggle="collapse"
        data-target="#navbarCollapse"
      >
        <span class="navbar-toggler-icon"></span>
        <span class="navbar-toggler-icon"></span>
        <span class="navbar-toggler-icon"></span>
      </button>

      <div
        class="collapse navbar-collapse justify-content-between"
        id="navbarCollapse"
      >
        <ul class="nav navbar-nav main-navigation my-0 mx-auto">
          {menu.map((main, index) => (
            <li key={main.name} class="nav-item">
              <a
                href={main.url}
                ref={(link) => (linkRefs.current[index] = link)}
                class={`nav-link text-sm-center p-lg-3 scroll ${
                  activeLink === index
                    ? "font-weight-bold text-primary"
                    : "text-dark"
                }`}
                onClick={() => handleLinkClick(index)}
              >
                {main.name}
              </a>
            </li>
          ))}
        </ul>
        <div class="navbar-nav">
          <a
            href={`${SERVER_URL}/app`}
            class="btn btn-primary btn-zoom hire_button"
          >
            Try TIA Now
          </a>
        </div>
      </div>
    </>
  );
};

export default NavBar;
