import React from "react";

const PrivacyNotice = () => {
  return (
    <>
      <div class="container my-5">
        <h3 class="privacy-notice-section mb-4">PRIVACY NOTICE</h3>

        <p class="mb-4">
          Stratpoint Technologies Inc./Stratpoint Global Outsourcing (Stratpoint
          Technologies) will be collecting your personal data to be able to
          identify you as a user of the TIA application. This Privacy Notice is
          in compliance with the Data Privacy Act of 2012 or R.A. 10173.
        </p>

        <h3 class="privacy-notice-section">
          What Personal Data are we collecting?
        </h3>

        <p class="mb-4">We will be requesting for the following information:</p>

        <p class="font-weight-bold ml-2">
          <u>For Identification and Contact Information</u>
        </p>
        <ul>
          <li>Full Name</li>
          <li>Mobile Number</li>
          <li>E-mail address</li>
          <li>Company</li>
        </ul>

        <h3 class="privacy-notice-section">What will your data be used for?</h3>

        <p class="mb-4">
          The personal data collected will be used to identify you as a distinct
          User in the application and to be able to send you notifications and
          reports about the projects you are assigned to.
        </p>

        <h3 class="privacy-notice-section">
          How is your data stored and who has access to your data?
        </h3>

        <p class="mb-4">
          Your data will be stored in electronic format in cloud servers, which
          are hosted in Singapore or the US. This may be accessed by the
          application Administrator and the project team you are assigned to.
          You have the facility to edit your own information.
        </p>

        <h3 class="privacy-notice-section">
          How does the Company protect your data?
        </h3>

        <p class="mb-4">
          Stratpoint Technologies takes the security of your data seriously. It
          has internal policies and controls in place to ensure that your data
          is not lost, accidentally destroyed, misused or disclosed, and is not
          accessed except by our employees in the proper performance of their
          duties.
        </p>

        <h3 class="privacy-notice-section">
          How long will we keep your personal data?
        </h3>

        <p class="mb-4">
          We will keep your data for as long as your project is active. For
          completed projects, the user data will be anonymized after three
          months. We will be retaining the project information for future
          reference and metrics benchmarking.
        </p>

        <h3 class="privacy-notice-section">Your Rights</h3>

        <p class="mb-4">As a data subject, you have the following rights:</p>

        <ul>
          <li>
            To be informed of the collection and processing of data relating to
            you and to know the purpose of the data processing;
          </li>
          <li>Obtain a copy of your data on request;</li>
          <li>The right to data portability;</li>
          <li>The right to correct, amend or remove inaccurate data;</li>
          <li>The right to know how long your data will be maintained;</li>
          <li>The right to delete or stop processing your data;</li>
          <li>
            The right to file a complaint to the National Privacy Commission and
            the right to be indemnified should your rights be violated.
          </li>
        </ul>

        <p>
          Should you have any questions or complaints, you may contact
          Stratpoint Technologies’ Data Protection Officer at{" "}
          <a href="mailto:sti-dpo@stratpoint.com">sti-dpo@stratpoint.com</a>.
        </p>
      </div>
    </>
  );
};

export default PrivacyNotice;
