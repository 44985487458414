import React from "react";

let SERVER_URL;
if (process.env.SERVER_URL !== undefined) {
  SERVER_URL = process.env.SERVER_URL;
} else {
  throw new Error("SERVER_URL environment variable is not defined.");
}

const HowItWorks = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg my-auto">
            <a href={`${SERVER_URL}/how-it-works/for-ios`}>
              <img
                className="img-fluid"
                src={`${SERVER_URL}/images/how-it-works/for-ios.png`}
                alt="For iOS"
              />
            </a>
            <div className="mt-3 text-center">
              <a href={`${SERVER_URL}/how-it-works/for-ios`}>
                <h3 className="text-decoration-none">For iOS</h3>
              </a>
            </div>
          </div>
          <div className="col-lg-5 my-auto h-100">
            <a href={`${SERVER_URL}/how-it-works/for-web`}>
              <img
                className="img-fluid h-100"
                src={`${SERVER_URL}/images/how-it-works/for-web.jpg`}
                alt="For Web"
              />
            </a>
            <div className="mt-3 text-center">
              <a href={`${SERVER_URL}/how-it-works/for-web`}>
                <h3 className="text-decoration-none">For Web</h3>
              </a>
            </div>
          </div>
          <div className="col-lg my-auto">
            <a href={`${SERVER_URL}/how-it-works/for-android`}>
              <img
                className="img-fluid"
                src={`${SERVER_URL}/images/how-it-works/for-android.png`}
                alt="For Android"
              />
            </a>
            <div className="mt-3 text-center">
              <a href={`${SERVER_URL}/how-it-works/for-android`}>
                <h3 className="text-decoration-none">For Android</h3>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowItWorks;
