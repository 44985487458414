import React from "react";
import ReactMarkdown from "react-markdown";

let SERVER_URL;
if (process.env.SERVER_URL !== undefined) {
  SERVER_URL = process.env.SERVER_URL;
} else {
  throw new Error("SERVER_URL environment variable is not defined.");
}

const AboutSection2 = ({ enable, topTitle, content }) => {
  if (!enable) {
    return null;
  }

  return (
    <>
      <div class="container">
        <div class="row text-center">
          <div class="col-lg-6 offset-lg-3">
            <div class="about_header mb-5">
              <h3 class="top-title">{topTitle}</h3>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="about_content">
              <div class="about_content-thumb about_content_2-thumb">
                <div class="about-svg">
                  <img
                    src={`${SERVER_URL}/images/hero/figure-svg.svg`}
                    alt="figure-svg"
                  />
                </div>
                <div class="about_content-thumb-image">
                  <img
                    src={`${SERVER_URL}/images/about/about-img2.jpg`}
                    alt="about-img"
                    style={{
                      WebkitMask: `url(${SERVER_URL}/images/about/about-mask-svg.png)`,
                      WebkitMaskSize: "100% 100%",
                    }}
                  />
                </div>
              </div>
              <div class="about_content-inner about_content_2-inner">
                <div class="about_content-inner-blob">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 600 600"
                  >
                    <defs>
                      <linearGradient
                        id="c"
                        x1="0.929"
                        y1="0.111"
                        x2="0.263"
                        y2="0.935"
                        gradientUnits="objectBoundingBox"
                      >
                        <stop offset="0" stop-color="#f1f6f9" />
                        <stop
                          offset="1"
                          stop-color="#f1f6f9"
                          stop-opacity="0"
                        />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div class="mb-4 text-light">
                  {content && <ReactMarkdown children={content} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutSection2;
