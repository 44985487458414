import React from "react";
import { useState } from "react";
import ReactMarkdown from "react-markdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";

const Faqs = () => {
  const [activeIndex, setActiveIndex] = useState(-1);

  const toggleAccordion = (index, e) => {
    e.stopPropagation();
    setActiveIndex(activeIndex === index ? -1 : index);
  };

  const renderIcon = (index) => {
    if (activeIndex === index) {
      return <FontAwesomeIcon icon={faAngleDown} />;
    } else {
      return <FontAwesomeIcon icon={faAngleRight} />;
    }
  };

  const faqs = [
    {
      question: "How can TIA help me on the agile/scrum process?",
      answer: `TIA can help you in your Agile/Scrum process by guiding you step-by-step on the Scrum events.`,
    },
    {
      question:
        "What is the difference between the Mobile App version and WebApp version of TIA?",
      answer:
        "In the Mobile App version, users can use all the features of TIA while the WebApp version focuses mainly on presentations during meetings.",
    },
    {
      question: "What are the Scrum Events covered in TIA?",
      answer:
        "Here are the Scrum Events covered in TIA:\n* Sprint Refinement\n* Sprint Planning\n* Sprint Run\n* Sprint Demo\n* Sprint Retro\n\n *Please note that Sprints must undergo all the events and cannot skip any of it.",
    },
    {
      question: "What are the roles and permissions in TIA?",
      answer:
        "Here are the Roles and Permissions in TIA:\n- Scrum Master\n  - Can start and end Scrum events meetings. Can add user stories.\n- Product Owner\n  - Can approve and reject user stories.\n- Developer\n  - Can approve and reject user stories.\n- Stakeholder\n  - Can view the projects",
    },
    {
      question: "Are there any applications that can be integrated in TIA?",
      answer:
        "As of now, TIA can be integrated with YouTrack but we are adding more applications in the near future.",
    },
    {
      question: "How can I join a project or a meeting?",
      answer:
        "The Scrum Master must display the correct QR Code and just simply click on Scan QR to join.",
    },
    {
      question:
        "If I change my mind and wants to deactivate my account, is it possible in TIA?",
      answer:
        "Yes, it is possible in TIA. You can just simply go to your profile and there is a button there to deactivate your account. Please take note that once you deactivate your account, you cannot retrieve all you previous data once you decided to reactivate again. Also, if you are the Scrum Master of a project, you must either re-assign the project to another Scrum Master or delete the project.",
    },
  ];

  return (
    <>
      <div class="accordion" id="faqAccordion">
        {faqs.map((faq, index) => (
          <div class="card" key={faq.question}>
            <div class="card-header p-0" id={`heading${index}`}>
              <button
                class="btn btn-link text-dark font-weight-bold faq-question-button d-flex justify-content-between align-items-center w-100"
                type="button"
                data-toggle="collapse"
                data-target={`#collapse${index}`}
                aria-expanded="false"
                aria-controls={`collapse${index}`}
                onClick={(e) => toggleAccordion(index, e)}
              >
                <span>{faq.question}</span>
                <span class="pl-2">{renderIcon(index)}</span>
              </button>
            </div>

            <div
              id={`collapse${index}`}
              class={`collapse ${activeIndex === index ? "show" : ""}`}
              aria-labelledby={`heading${index}`}
              data-parent="#faqAccordion"
            >
              <div class="card-body justify-content-start text-left">
                {<ReactMarkdown children={faq.answer} />}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Faqs;
