import React from "react";
import { createRoot } from "react-dom/client";
import ReactDOMServer from "react-dom/server";

import NavBar from "./components/partials/NavBar";
import HeroSection from "./components/home/HeroSection";
import AboutSection from "./components/home/AboutSection";
import AboutSection2 from "./components/home/AboutSection2";
import Footer from "./components/partials/Footer";
import Faqs from "./components/faqs/Faqs";
import HowItWorks from "./components/how-it-works/HowItWorks";
import HowItWorksManual from "./components/how-it-works/HowItWorksManual.js";
import ContactUs from "./components/contact/ContactUs";
import PrivacyNotice from "./components/privacy-notice/PrivacyNotice";
import Invite from "./components/invite/Invite";

// Fetch data from Hugo's data files
import heroData from "../data/hero.yml";
import aboutData from "../data/aboutSection.yml";
import aboutData2 from "../data/aboutSection2.yml";

// Render react components
const inviteElement = document.getElementById("invite");
if (inviteElement && typeof inviteElement === "object") {
  const rootElement = document.getElementsByTagName("html")[0];
  const root = ReactDOMServer.renderToStaticMarkup(<Invite />);
  rootElement.innerHTML = root;
}

const navbarElement = document.getElementById("navbar");
if (navbarElement && typeof navbarElement === "object") {
  const navbar = createRoot(navbarElement);
  navbar.render(<NavBar />);
}

const homeElement = document.getElementById("home");
if (homeElement && typeof homeElement === "object") {
  const home = createRoot(homeElement);
  home.render(
    <HeroSection
      enable={heroData.enable}
      topTitle={heroData.topTitle}
      content={heroData.content}
    />
  );
}

const aboutElement = document.getElementById("about");
if (aboutElement && typeof aboutElement === "object") {
  const about = createRoot(aboutElement);
  about.render(
    <AboutSection
      enable={aboutData.enable}
      topTitle={aboutData.topTitle}
      content={aboutData.content}
    />
  );
}

const about2Element = document.getElementById("about2");
if (about2Element && typeof about2Element === "object") {
  const about2 = createRoot(about2Element);
  about2.render(
    <AboutSection2
      enable={aboutData2.enable}
      topTitle={aboutData2.topTitle}
      content={aboutData2.content}
    />
  );
}

const faqsElement = document.getElementById("faqs");
if (faqsElement && typeof faqsElement === "object") {
  const faqs = createRoot(faqsElement);
  faqs.render(<Faqs />);
}

const howItWorksElement = document.getElementById("how-it-works");
if (howItWorksElement && typeof howItWorksElement === "object") {
  const howItWorks = createRoot(howItWorksElement);
  howItWorks.render(<HowItWorks />);
}

const howItWorksAndroidElement = document.getElementById(
  "how-it-works-android"
);
if (howItWorksAndroidElement && typeof howItWorksAndroidElement === "object") {
  const howItWorksAndroid = createRoot(howItWorksAndroidElement);
  howItWorksAndroid.render(<HowItWorksManual type="android" />);
}

const howItWorksIOSElement = document.getElementById("how-it-works-ios");
if (howItWorksIOSElement && typeof howItWorksIOSElement === "object") {
  const howItWorksIOS = createRoot(howItWorksIOSElement);
  howItWorksIOS.render(<HowItWorksManual type="ios" />);
}

const howItWorksWebElement = document.getElementById("how-it-works-web");
if (howItWorksWebElement && typeof howItWorksWebElement === "object") {
  const howItWorksWeb = createRoot(howItWorksWebElement);
  howItWorksWeb.render(<HowItWorksManual type="web" />);
}

const contactUsElement = document.getElementById("contact-us");
if (contactUsElement && typeof contactUsElement === "object") {
  const contactUs = createRoot(contactUsElement);
  contactUs.render(<ContactUs />);
}

const privacyNoticeElement = document.getElementById("privacy-notice");
if (privacyNoticeElement && typeof privacyNoticeElement === "object") {
  const privacyNotice = createRoot(privacyNoticeElement);
  privacyNotice.render(<PrivacyNotice />);
}

const footerElement = document.getElementById("footer");
if (footerElement && typeof footerElement === "object") {
  const footer = createRoot(footerElement);
  footer.render(<Footer />);
}
