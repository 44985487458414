import React from "react";
import { PDFObject } from "react-pdfobject";

let SERVER_URL;
if (process.env.SERVER_URL !== undefined) {
  SERVER_URL = process.env.SERVER_URL;
} else {
  throw new Error("SERVER_URL environment variable is not defined.");
}

function HowItWorksManual({ type }) {
  if (type === "android")
    return (
      <>
        <div id="pdfContainer">
          <PDFObject
            url={`${SERVER_URL}/pdf/amstel-user-manual-mobile(android).pdf`}
            height="600px"
          />
        </div>
      </>
    );

  if (type === "ios")
    return (
      <>
        <div id="pdfContainer">
          <PDFObject
            url={`${SERVER_URL}/pdf/amstel-user-manual-mobile(ios).pdf`}
            height="600px"
          />
        </div>
      </>
    );

  if (type === "web")
    return (
      <>
        <div id="pdfContainer">
          <PDFObject
            url={`${SERVER_URL}/pdf/amstel-user-manual-web.pdf`}
            height="600px"
          />
        </div>
      </>
    );

  return <></>;
}

export default HowItWorksManual;
