import React from "react";
import ReactMarkdown from "react-markdown";

let SERVER_URL;
if (process.env.SERVER_URL !== undefined) {
  SERVER_URL = process.env.SERVER_URL;
} else {
  throw new Error("SERVER_URL environment variable is not defined.");
}

const HeroSection = ({ enable, topTitle, content }) => {
  if (!enable) {
    return null;
  }
  return (
    <>
      <div class="hero_background-svg">
        <svg viewBox="0 0 1437 1180">
          <path
            fill="#f1f6f9"
            d="M0 0h1377l60 1010a120 120 0 01-120 120L0 1180z"
            data-name="Path 1350"
          />
        </svg>
      </div>
      <div class="hero_footer-svg">
        <img src={`${SERVER_URL}/images/hero/figure-svg.svg`} alt="" />
      </div>
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="hero_content p-0">
              <div class="hero_blob">
                <svg viewBox="0 0 550 550">
                  <defs>
                    <linearGradient
                      id="a"
                      x1=".069"
                      x2=".753"
                      y1=".116"
                      y2=".858"
                      gradientUnits="objectBoundingBox"
                    >
                      <stop offset="0" stop-color="#fff" />
                      <stop offset="1" stop-color="#fff" stop-opacity=".012" />
                    </linearGradient>
                  </defs>
                  <g data-name="blob-shape (3)">
                    <path
                      class="blob"
                      fill="url(#a)"
                      d="M455.4 151.1c43.1 36.7 73.4 92.8 60.8 136.3-12.7 43.5-68.1 74.4-111.3 119.4-43.1 45-74 104.1-109.8 109-35.9 5-76.7-44.2-111.8-89.2-35.2-45-64.7-85.8-70.8-132.6-6-46.8 11.6-99.6 46.7-136.3 35.2-36.6 88-57.2 142.4-58.8 54.5-1.7 110.6 15.6 153.8 52.2z"
                    />
                  </g>
                </svg>
              </div>
              <div class="mb-5">
                <h1 class="top-title justify-content-start hero-top-title">
                  {topTitle && <ReactMarkdown children={topTitle} />}
                </h1>
                <div class="top-title justify-content-start pl-3">{content && <ReactMarkdown children={content} />}</div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="hero_figure">
              <div class="figure-svg">
                <img
                  src={`${SERVER_URL}/images/hero/figure-svg.svg`}
                  alt="figure-svg"
                />
              </div>
              <img
                src={`${SERVER_URL}/images/hero/2960351.jpg`}
                alt="hero-image"
                style={{
                  WebkitMask: `url(${SERVER_URL}/images/hero/hero-mask-svg.png)`,
                  WebkitMaskRepeat: "no-repeat",
                  WebkitMaskSize: "contain",
                  WebkitMaskPosition: "center center",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
