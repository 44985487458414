import React from "react";
import ReactMarkdown from "react-markdown";

let SERVER_URL;
if (process.env.SERVER_URL !== undefined) {
  SERVER_URL = process.env.SERVER_URL;
} else {
  throw new Error("SERVER_URL environment variable is not defined.");
}

const AboutSection = ({ enable, topTitle, content }) => {
  if (!enable) {
    return null;
  }

  return (
    <>
      <div class="container">
        <div class="row text-center">
          <div class="col-lg-6 offset-lg-3">
            <div class="about_header mb-5">
              <h3 class="top-title">{topTitle}</h3>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="about_content">
              <div class="about_content-thumb">
                <div class="about-svg">
                  <img
                    src={`${SERVER_URL}/images/hero/figure-svg.svg`}
                    alt="figure-svg"
                  />
                </div>
                <div class="about_content-thumb-image">
                  <img
                    src={`${SERVER_URL}/images/about/about-img1.jpg`}
                    alt="about-img"
                    style={{
                      WebkitMask: `url(${SERVER_URL}/images/about/about-mask-svg.svg)`,
                      WebkitMaskSize: "100% auto",
                    }}
                  />
                </div>
              </div>
              <div class="about_content-inner">
                <div class="about_content-inner-blob">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 600 600"
                  >
                    <defs>
                      <linearGradient
                        id="c"
                        x1="0.929"
                        y1="0.111"
                        x2="0.263"
                        y2="0.935"
                        gradientUnits="objectBoundingBox"
                      >
                        <stop offset="0" stop-color="#f1f6f9" />
                        <stop
                          offset="1"
                          stop-color="#f1f6f9"
                          stop-opacity="0"
                        />
                      </linearGradient>
                    </defs>
                    <g data-name="blob-shape (3)">
                      <path
                        class="blob"
                        fill="url(#c)"
                        d="M455.4 151.1c43.1 36.7 73.4 92.8 60.8 136.3-12.7 43.5-68.1 74.4-111.3 119.4-43.1 45-74 104.1-109.8 109-35.9 5-76.7-44.2-111.8-89.2-35.2-45-64.7-85.8-70.8-132.6-6-46.8 11.6-99.6 46.7-136.3 35.2-36.6 88-57.2 142.4-58.8 54.5-1.7 110.6 15.6 153.8 52.2z"
                      />
                    </g>
                  </svg>
                </div>
                <div class="mb-4 text-light">
                  {content && <ReactMarkdown children={content} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutSection;
