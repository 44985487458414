import React from "react";

function ContactUs() {
  return (
    <>
      <div className="m-auto">
        <iframe
          title="Google Form"
          src="https://docs.google.com/forms/d/e/1FAIpQLSc5OdvoCAtlNUsed4Flz1N-eYNApldNquSNJD725vBdDDUVcg/viewform?embedded=true"
          width="100%"
          height="1080px"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
        >
          {/* Loading... */}
        </iframe>
      </div>
    </>
  );
}

export default ContactUs;
